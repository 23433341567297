jQuery(document).ready(($) => {
  /* =======================================================================
   Search form
======================================================================= */

  $(".search-link").on("click", (e) => {
    e.preventDefault();

    $(".search-form-container").show();
  });

  $(document).on("mouseup", (e) => {
    const container = $(".search-form-container");

    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
    }
  });

  /* =======================================================================
   Tours heading
======================================================================= */

  $(".single-experiences .header-heading").html(
    $(".single-experiences .header-heading")
      .text()
      .replace(" Rail Tour", "<br>Rail Tour")
      .replace(" Aurora", "<br>Aurora")
  );

  /* =======================================================================
   Set vw without horizontal scrollbar
======================================================================= */

  function setVw() {
    const vw = document.documentElement.clientWidth / 100;
    document.documentElement.style.setProperty("--vw", "".concat(vw, "px"));
  }

  setVw();

  window.addEventListener("resize", (params) => {
    setVw();
  });

  /* =======================================================================
   Brochure slider
======================================================================= */

  $(".brochure-slider").slick({
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "16%",
    speed: 1000,
    slidesToShow: 3,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  /* =======================================================================
   Testimonials
======================================================================= */

  const $testimonials = $(".testimonials").masonry({
    itemSelector: ".testimonial",
    columnWidth: ".grid-sizer",
    percentPosition: "true",
    gutter: ".gutter-sizer",
  });
  const MAX_TESTIMONIALS_PER_LOAD = 10;

  $(".testimonial-load-more").on("click", (e) => {
    e.preventDefault();
    const selector =
      $("#testimonials-tour-filter").val() &&
      $("#testimonials-tour-filter").val() !== "all-tours"
        ? `.${$("#testimonials-tour-filter").val()}`
        : ".testimonial";

    $(`${selector}:not(:visible)`).each((index, element) => {
      if (index + 1 > MAX_TESTIMONIALS_PER_LOAD) {
        return false;
      }

      $(element).show();
    });

    $testimonials.masonry();
  });

  $("#testimonials-tour-filter").on("change", function (e) {
    e.preventDefault();

    if ($(this).val() !== "all-tours") {
      $(".testimonial").hide();

      $(`.testimonial.${$(this).val()}`).each((index, element) => {
        if (index + 1 > MAX_TESTIMONIALS_PER_LOAD) {
          return false;
        }

        $(element).show();
      });

      $testimonials.masonry();
    } else {
      $(".testimonial").hide();

      $(".testimonial").each((index, element) => {
        if (index + 1 > MAX_TESTIMONIALS_PER_LOAD) {
          return false;
        }
        $(element).show();
      });

      $testimonials.masonry();
    }
  });

  /* =======================================================================
   Gallery
======================================================================= */

  const $gallery = $(".gallery").masonry({
    itemSelector: ".gallery-image",
    columnWidth: ".grid-sizer",
    percentPosition: "true",
    gutter: ".gutter-sizer",
  });
  const MAX_IMAGES_PER_LOAD = 10;

  $(".gallery-load-more").on("click", (e) => {
    e.preventDefault();
    const selector =
      $("#gallery-tour-filter").val() &&
      $("#gallery-tour-filter").val() !== "all-tours"
        ? `.${$("#gallery-tour-filter").val()}`
        : ".gallery-image";

    $(`${selector}:not(:visible)`).each((index, element) => {
      if (index + 1 > MAX_IMAGES_PER_LOAD) {
        return false;
      }

      $(element).show();
    });

    $gallery.masonry();
  });

  $("#gallery-tour-filter").on("change", function (e) {
    e.preventDefault();

    if ($(this).val() !== "all-tours") {
      $(".gallery-image").hide();

      $(`.gallery-image.${$(this).val()}`).each((index, element) => {
        if (index + 1 > MAX_IMAGES_PER_LOAD) {
          return false;
        }

        $(element).show();
      });

      $gallery.masonry();
    } else {
      $(".gallery-image").hide();

      $(".gallery-image").each((index, element) => {
        if (index + 1 > MAX_IMAGES_PER_LOAD) {
          return false;
        }
        $(element).show();
      });

      $gallery.masonry();
    }
  });

  /* =======================================================================
   Custom select
======================================================================= */

  customSelect();

  function customSelect() {
    let x;
    let i;
    let j;
    let l;
    let ll;
    let selElmnt;
    let a;
    let b;
    let c;
    /* Look for any elements with the class "custom-select": */
    x = document.getElementsByClassName("custom-select");
    l = x.length;
    for (i = 0; i < l; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      ll = selElmnt.length;
      /* For each element, create a new DIV that will act as the selected item: */
      a = document.createElement("DIV");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      x[i].appendChild(a);
      /* For each element, create a new DIV that will contain the option list: */
      b = document.createElement("DIV");
      b.setAttribute("class", "select-items select-hide");
      for (j = 1; j < ll; j++) {
        /* For each option in the original select element,
    create a new DIV that will act as an option item: */
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
          /* When an item is clicked, update the original select box,
        and the selected item: */
          let y;
          let i;
          let k;
          let s;
          let h;
          let sl;
          let yl;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          sl = s.length;
          h = this.parentNode.previousSibling;
          for (i = 0; i < sl; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              jQuery(s).trigger("change");
              h.innerHTML = this.innerHTML;
              y = this.parentNode.getElementsByClassName("same-as-selected");
              yl = y.length;
              for (k = 0; k < yl; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });
    }

    function closeAllSelect(elmnt) {
      /* A function that will close all select boxes in the document,
  except the current select box: */
      let x;
      let y;
      let i;
      let xl;
      let yl;
      const arrNo = [];
      x = document.getElementsByClassName("select-items");
      y = document.getElementsByClassName("select-selected");
      xl = x.length;
      yl = y.length;
      for (i = 0; i < yl; i++) {
        if (elmnt == y[i]) {
          arrNo.push(i);
        } else {
          y[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < xl; i++) {
        if (arrNo.indexOf(i)) {
          x[i].classList.add("select-hide");
        }
      }
    }

    /* If the user clicks anywhere outside the select box,
then close all select boxes: */
    document.addEventListener("click", closeAllSelect);
  }

  /* =======================================================================
   Agents
======================================================================= */

  $(document).on("click", ".agent-toggle", function () {
    $(this).nextAll(".agent").slideToggle();
  });

  $(document).on("click", ".state", function () {
    $("#agents-list").show();
    $("#agents-search").hide();

    $(".state").removeClass("active");
    $(this).addClass("active");
    $(".agents-by-state").hide();
    $(`.${$(this).text().toLowerCase().replaceAll(" ", "-")}-agents`).css(
      "display",
      "grid"
    );
  });

  /* =======================================================================
    Book Now banner scroll shadow
======================================================================= */

  $(window).scroll(() => {
    if ($(window).scrollTop() > 10) {
      $(".book-now-banner").addClass("book-now-banner--sticky");
    } else {
      $(".book-now-banner").removeClass("book-now-banner--sticky");
    }
  });
});
